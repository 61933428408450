import {Disease} from '@matchsource/api-generated/subject';
import {DiseaseModel} from '@matchsource/models/nomenclature';

export const fromDTO = (input: Disease): DiseaseModel => {
  return {
    code: input.code,
    description: input.description,
    malignant: input.malignant,
  };
};
