import {
  addOrReplaceEntities,
  defaultEntitiesState,
  EntitiesStateModel,
  loadedEntities,
  loadingEntities,
  setError,
} from '@matchsource/store/core';
import {DiseaseModel} from '@matchsource/models/nomenclature';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {DiseasesApiService} from '@matchsource/api/diseases';
import {GetDiseases} from './diseases.actions';
import {catchError, tap} from 'rxjs/operators';
import {compose} from '@ngxs/store/operators';
import {of} from 'rxjs';

export type DiseasesStateModel = EntitiesStateModel<DiseaseModel>;

@State<DiseasesStateModel>({
  name: 'diseases',
  defaults: defaultEntitiesState(),
})
@Injectable()
export class DiseasesState {
  constructor(private readonly diseaseApi: DiseasesApiService) {}

  @Selector([DiseasesState])
  static diseases(state: DiseasesStateModel) {
    return state.entities;
  }

  @Selector([DiseasesState])
  static inProgressOrLoaded(state: DiseasesStateModel) {
    return state.loaded || state.loading;
  }

  @Action(GetDiseases)
  getAll(ctx: StateContext<DiseasesStateModel>) {
    ctx.setState(loadingEntities(true));

    return this.diseaseApi.getDiseases().pipe(
      catchError(error => {
        ctx.setState(compose(setError(error), loadingEntities(false)));
        return of([]);
      }),
      tap(diseases =>
        ctx.setState(
          compose(addOrReplaceEntities<DiseaseModel>('code', diseases), loadedEntities(true), loadingEntities(false))
        )
      )
    );
  }
}
