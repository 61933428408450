import {DiseasesState, DiseasesStateModel} from './diseases.state';
import {Injectable, OnDestroy} from '@angular/core';
import {combineLatest, Observable, of, Subject} from 'rxjs';
import {DiseaseModel} from '@matchsource/models/nomenclature';
import {Store} from '@ngxs/store';
import {distinctUntilChanged, map, shareReplay, takeUntil, tap} from 'rxjs/operators';
import {GetDiseases} from './diseases.actions';

const byCode = (state: DiseasesStateModel) => (code: string) => state.entities[code];

@Injectable({
  providedIn: 'root',
})
export class DiseaseService implements OnDestroy {
  public readonly data$: Observable<DiseasesStateModel>;
  public readonly diseases$: Observable<MsApp.Dictionary<DiseaseModel>>;

  private readonly destroy$ = new Subject<void>();

  constructor(private readonly store: Store) {
    this.data$ = this.store.select<DiseasesStateModel>(DiseasesState).pipe(
      distinctUntilChanged(),
      tap(() => {
        if (!this.store.selectSnapshot(DiseasesState.inProgressOrLoaded)) {
          this.store.dispatch(new GetDiseases());
        }
      }),
      takeUntil(this.destroy$),
      shareReplay({refCount: true, bufferSize: 1})
    );

    this.diseases$ = this.data$.pipe(map((state: DiseasesStateModel) => state.entities));
  }

  getByCode(code: string): Observable<DiseaseModel> {
    return combineLatest([of(code), this.data$]).pipe(map(([diseaseCode, data]) => byCode(data)(diseaseCode)));
  }

  load(): void {
    this.store.dispatch(new GetDiseases());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
